import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../../assets/scss/webProfile.scss";

import {QRCodeCanvas} from 'qrcode.react';
import { Modal } from "react-bootstrap";
const WebsiteLayout = () => {
  const location = useLocation()
  const userDetails = location?.state?.userDetails
  const [showQRModal, setShowQRModal] = useState(false); // Track QR modal visibility
  return (
    <>
      <div className="webProfile">
        <header>
          <div className="webconatainer">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <img src="img/toggleMenu.svg" alt="toggleMenu" />
              </div>
              <div>
                {userDetails?.logo ?
                  <img src={process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.logo} alt="logo" className="logowb" /> : <span className="text-white">LOGO</span>}
              </div>
              <div>
                <img  onClick={()=>setShowQRModal(true)} src="img/menuHead.svg" alt="menuHead" className="me-3" />
                {/* <img src="img/SearchHead.svg" alt="SearchHead" /> */}
              </div>
            </div>

          </div>
        </header>
        <Outlet />
      
        <footer>

          <div className="contactus">
            <div className="webconatainer">
              <h2 className="head2 text-md-center text-white">Contact Us</h2>
              <div className="whiteboxtxt mt-sm-4 mt-4">

                <div className="labletxt">Business Phone Number</div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="wldata">{userDetails?.Business_phone || "N/A"}</span>
                  <span className="ms-2">
                    <img src="img/webPhone.svg" alt="svg" />
                  </span>
                </div>
                <div className="gap-dots-border my-3"></div>

                <div>
                  <div className="labletxt">Email</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="wldata">{userDetails?.email || "N/A"}</span>
                    <span className="ms-2">
                      <img src="img/webmsg.svg" alt="svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </footer>

            {/* Modal for QR Code */}
            <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered size="sm" className="modal-sm">
                <Modal.Header closeButton>
                    <Modal.Title>Scan QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <QRCodeCanvas
                        value="https://www.buildfolio.co/#/?qredirect=true"
                        size={256}
                        level={"H"}
                        includeMargin={true}
                    />
                    <p className="mt-2">Scan to visit Buildfolio</p>
                </Modal.Body>
            </Modal>

      </div>


    </>
  );
};
export default WebsiteLayout;

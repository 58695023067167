import React, { useEffect, useState } from "react";
import MyPagination from "../../components/Pagination/MyPagination";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import { useAppContext } from "../../context/SharedData";
import getLicenseList from "../../api/getLicenseList";
import { formatDate } from "../../utils/dateConverter";
import alert from "../../utils/alert";
import { ContractorProfile } from "../profile/contractorProfile";
import useDivLoader from "../../components/loaders/useDivLoader";
import Axios from "../../axios/axiosInstance";
import BreadCrumbsDiv from "../../components/common/BreadCrumbsDiv";
import CustomModal from "../../components/common/CustomModal";
import JobTypeFilter from "../../components/common/JobTypeFilter";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { UserProfile } from "../profile/UserProfile";
import { formatUrl } from "../../utils/formatUrl";

const DashboardList = ({ isFavPanel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState();
  const [userId, setUserId] = useState("");
  const [isUserVerified, setIsUserVerified] = useState(false)
  const { showLoader, hideLoader, Loader, isLoading } = useDivLoader();
  const { sharedData, contractorDetails, refreshPage, setRefreshPage, isClaimBtnActive } = useAppContext();
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [typeOfModal, setTypeOfModal] = useState("");
  const [showVerifiedList, setShowVerifiedList] = useState(false);
  //for create group
  const [favIds, selecteFavIds] = useState([]);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [groupData, setFavGroup] = useState();

  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get("name")
  let { groupId } = useParams();


  function detectDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /mobile|android|iphone|ipad|iemobile|opera mini/i.test(userAgent);
    const isTablet = /ipad|android|tablet/i.test(userAgent);

    if (isMobile || isTablet) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  const [deviceType, setDeviceType] = useState("Desktop");

  useEffect(() => {
    const type = detectDevice();
    setDeviceType(type);
  });

  console.log("fafafaffafa", favIds)


  useEffect(() => {
    if (shouldFetchData) {
      getData();
      setShouldFetchData(false); // Reset the flag
    }
  }, [shouldFetchData]); // Dependencies include shouldFetchData

  const onClose = () => {
    setUserId("");
    setIsOpen(false);
  };

  useEffect(() => {
    if (refreshPage) {
      setIsChecked(false)
      setFavGroup()
      setShowVerifiedList(false)
    }
    groupData && !refreshPage ? groupListing() : getData()
  }, [currentPage, refreshPage])

  useEffect(() => {

    showLoader();
    const debounceTimer = setTimeout(() => {

      (isChecked && !groupId && !refreshPage) ? groupListing() : getData();
      if (sharedData.search || sharedData.type || sharedData.city || sharedData.zip || showVerifiedList || groupId) {
        setCurrentPage(1);
        if (groupId) {
          setFavGroup();
          sharedData.search = ''
        }
      }
    }, 300); // Adjust debounce delay as needed (e.g., 300 milliseconds)

    return () => clearTimeout(debounceTimer);
  }, [sharedData.search, sharedData.type, sharedData.city, sharedData.zip, showVerifiedList, groupId]);

  //isFavPanel show that component is used by Favorites panel
  const getData = async () => {

    if (isFavPanel) {
      await Axios.get(
        `license/favorite?search=${sharedData?.search ? sharedData?.search.replace(/#/g, '') : ''
        }&page=${currentPage}&limit=40&status=${showVerifiedList}&profile_id=${localStorage.getItem("l_id")}${groupId ? `&group_id=${groupId}` : ''}`
      )
        .then((res) => {
          const favIdsSet = new Set(favIds);
          res.data.data.forEach((element) => {
            // Check if the element id exists in the Set
            element.selectAllUsers = favIdsSet.has(element.id);
          });
          setData(res.data);

        })
        .catch((err) => {
          err.response && alert("error", err.response.data.error);
        })
        .finally(() => {
          refreshPage && setRefreshPage(!refreshPage)
          hideLoader();
        });
    } else {
      await getLicenseList(sharedData, currentPage, 40, showVerifiedList)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          err.response && alert("error", err.response.data.error);
        })
        .finally(() => {
          refreshPage && setRefreshPage(!refreshPage)
          hideLoader();
        });
    }
  };


  //for getting group listing
  const groupListing = async (page) => {

    showLoader();
    await Axios.get(`license/fav-group/${localStorage.getItem("l_id")}?search=${sharedData?.search ? sharedData?.search.replace(/#/g, '') : ''}&limit=40&page=${page ? page : currentPage}`)
      .then((res) => {
        setFavGroup(res?.data);
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        refreshPage && setRefreshPage(!refreshPage)
        hideLoader();
      });
  }


  // on checked check box selection
  const onCheckboxChange = (e, eleId) => {
    const { checked } = e.target;

    // Update the favIds state
    selecteFavIds(prevFavIds => {
      if (checked) {
        return [...prevFavIds, eleId];
      } else {
        return prevFavIds.filter(id => id !== eleId);
      }
    });

    // Update the data state
    setData(prevData => {
      const updatedData = prevData.data.map(ele => {
        if (ele.id === eleId) {
          return { ...ele, selectAllUsers: checked };
        }
        return ele;
      });

      return { ...prevData, data: updatedData };
    });
  };



  //for creating a group
  const createGroup = async (data) => {
    showLoader();
    await Axios.post("license/fav-group", {
      name: data?.name?.trim(),
      licenseIds: favIds,
      profile_id: localStorage.getItem("l_id")
    })
      .then((res) => {
        alert("success", res.data.message);
        selecteFavIds([]);
        setCurrentPage(1);
        setShouldFetchData(true); // Trigger data fetch state
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        refreshPage && setRefreshPage(!refreshPage)
        hideLoader();
      });
  }


  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setShowVerifiedList(false)
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      groupListing(1);
      setData();
    } else {
      getData();
      setFavGroup();
    }

  };





  const toggleFavorite = (id) => {
    // Find the index of the item in data
    const index = data.data.findIndex((item) => item.id === id);
    // Create a copy of data
    const updatedData = { ...data };
    // Toggle the favorite status
    updatedData.data[index].favorites = !updatedData.data[index].favorites;
    // Update the state with the modified data
    setData(updatedData);
  };

  const onClickFavourite = (id) => {
    Axios.put(`license/favorite/${id}/${localStorage.getItem("l_id")}`)
      .then((res) => {
        alert("success", res.data.message);

        isFavPanel && getData();
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        hideLoader();
      });
  };


  return (
    <>
      <div className="d-flex  align-items-center flex-wrap">
        <div className="d-flex align-items-center mobile_fjbt">
          <h2 className="title">{isFavPanel ? groupData ? "Group List" : groupId
            ? (
              <>
                <Link to="/favourite" onClick={() => {
                  setCurrentPage(1)
                  setData();
                }} style={{ textDecoration: 'none', color: '#646464' }}>
                  Group List
                </Link>/{groupName}
              </>
            ) : "Favorite" : "Dashboard"}

          </h2>
          {(isFavPanel && !groupId) && <div className="d-flex d-md-none align-items-center ms-2">
            <input
              className="inputFill border-0 me-2 max-wf"
              type="checkbox"
              id="checkboxWebgrp"
              checked={isChecked}
              onChange={handleCheckboxChange} // Handle checkbox change here
            />
            <label htmlFor="checkboxWebgrp" >
              Group Listing
            </label>

          </div>
          }
          {(!groupId && !isChecked) && <div className="d-flex d-md-none align-items-center ms-2">
            <input
              checked={showVerifiedList ? true : false}
              className="inputFill border-0 me-2 max-wf"
              onChange={(e) => { setShowVerifiedList(e.target.checked) }}
              type="checkbox"
              id="checkboxWebh"
            />
            <label htmlFor="checkboxWebh" >
              Verified Users
            </label>

          </div>
          }

          {!isFavPanel &&
            <div className="header p-0 border-bottom-0 ms-3 d-none d-md-block">
              <div className="filter-dropdown filterHiring">
                <JobTypeFilter />
              </div>
            </div>}
        </div>
        {/* {!localStorage.getItem("user_token") && */}
        <div className="d-flex ms-auto">
          {(isFavPanel && !groupId) && <div className="d-md-flex d-none  align-items-center me-3">
            <input
              className="inputFill border-0 me-2 max-wf"
              type="checkbox"
              id="checkboxWebgrp"
              checked={isChecked}
              onChange={handleCheckboxChange} // Handle checkbox change here
            />
            <label htmlFor="checkboxWebgrp" >
              Group Listing
            </label>
          </div>}
          {(!groupId && !isChecked) && <div className="d-md-flex d-none  align-items-center me-3">
            <input
              checked={showVerifiedList ? true : false}
              className="inputFill border-0 me-2 max-wf"
              onChange={(e) => { setShowVerifiedList(e.target.checked) }}
              type="checkbox"
              id="checkboxWeb"
            />
            <label htmlFor="checkboxWeb" >
              Verified Users
            </label>

          </div>}
          {isFavPanel && (favIds.length > 1) && <button className="btn_cus wfc ms-auto py-2 d-flex align-items-center d-none d-md-block" onClick={() => {
            setTypeOfModal("createGroup");
            setShow(true);
          }}>
            <img src="img/addScreen.svg" alt="addScreen" className="me-1" /> Create Group
          </button>}
          {((isClaimBtnActive || !localStorage.getItem("user_token")) && !location.pathname.includes("/favourite")) &&
            <button onClick={() => navigate(`/welcome?isclaim=${localStorage.getItem("user_token") ? true : false}`)} className="btn_cus wfc py-2 d-xl-inline d-none ms-2">Claim {localStorage.getItem("user_token") ? "another" : "your"} profile</button>
          }
        </div>
        {/* } */}

      </div>
      {/* {console.log('data?.totalRecordsdata?.totalRecords', data)} */}
      {contractorDetails && <BreadCrumbsDiv contractorDetails={contractorDetails} />}
      <div className="d-flex align-items-center mb-3 justify-content-bitween mt-3">
        <div className="page d-flex align-items-center text-nowrap d-xl-none">
          {(groupData?.totalRecords > 0 || data?.totalRecords > 0) && (
            <span>
              Showing {(groupData?.totalRecords || data?.totalRecords)}
              {(groupData?.totalRecords === 1 || data?.totalRecords === 1) ? ' Entry' : ' Entries'}
            </span>
          )}

        </div>
        {isFavPanel && (favIds.length > 1) && <button className="btn_cus wfc ms-auto py-2 d-xl-none d-flex align-items-center" onClick={() => {
          setTypeOfModal("createGroup");
          setShow(true);
        }}>
          <img src="img/addScreen.svg" alt="addScreen" className="me-1" /> Create Group
        </button>}
        {((isClaimBtnActive || !localStorage.getItem("user_token")) && !location.pathname.includes("/favourite")) &&
          <button onClick={() => navigate(`/welcome?isclaim=${localStorage.getItem("user_token") ? true : false}`)} className="btn_cus wfc ms-auto py-2 d-xl-none">Claim {localStorage.getItem("user_token") ? "another" : "your"} profile</button>
        }
      </div>
      <div className={`tbwrp  mt-3 ${isLoading ? "ios" : ""}`}>
        <table className="minW1470">
          <thead>
            {groupData ?
              <tr>
                <th>Created Date</th>
                <th>Group Name</th>
                <th>Total Records</th>
                <th className="action">Action</th>
              </tr>
              :
              <tr>
                <th width="15%">Business Name</th>
                <th width="17%">Contact Name</th>
                <th width="10%">City</th>
                {/* <th>State</th>
                <th width="10%">Zip</th> */}
                <th width="15%">Lic. Type</th>
                {/* <th>SP Code</th> */}
                <th width="15%">SP Code Desc</th>
                {/* <th>Subscriber</th> */}
                <th>Exp. DT.</th>
                {/* <th>View Details</th> */}
                <th className="action">Action</th>
                {/* {!groupId && <th></th>} */}
              </tr>
            }

          </thead>
          <tbody className="ldr">
            {!isLoading && (
              groupData ? (

                groupData?.data.map((ele, index) => {
                  return (
                    <tr key={index} className="mt-0">

                      <td className="position-relative">
                        <div className="mobileH">Created Date</div>
                        <div className="d-flex pointer" onClick={() => navigate(`/favourite/${ele?.id}?name=${ele?.name}`)}>
                          {/* <span className="bluetick d-none d-lg-block">{ele?.status === "verified" && <img src="img/checkBlue.svg" alt="checkBlue" className="me-1" />}</span> */}
                          {/* <span className="mobile_boltxt"></span> */}
                          {formatDate(ele?.created_at)}
                          {/* <span className="bluetick d-lg-none d-block">{ele?.status === "verified" && <img src="img/checkBlue.svg" alt="checkBlue" className="ms-1" />}</span> */}
                        </div>
                      </td>
                      <td onClick={() => navigate(`/favourite/${ele?.id}?name=${ele?.name}`)} className="pointer">
                        <div className="mobileH">	Group Name</div>
                        {ele?.name}
                      </td>
                      <td onClick={() => navigate(`/favourite/${ele?.id}?name=${ele?.name}`)} className="pointer">
                        <div className="mobileH">Total Records</div>
                        {ele?.group_count}
                      </td>
                      <td>
                        <div className="mobileH">Action</div>
                        <div className="d-flex align-items-center">
                          {ele.message ? (
                            <span
                              onClick={() => alert("info", "Already sent SMS")}
                              className="me-3 pointer"
                            >
                              {" "}
                              <img src="img/chatdisable.svg" alt="mgs icon" />
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                if (deviceType === "Desktop") {
                                  alert("info", "This feature only works in mobile device");
                                } else {
                                  if (!localStorage.getItem("user_token")) {
                                    setTypeOfModal("login")
                                    setUserDetails("Please register with your business phone number, email, and mobile number to access SMS feature.")
                                    // setUserDetails(ele);
                                    setShow(true);
                                  } else {
                                    setTypeOfModal("info");
                                    setUserDetails(ele);
                                    setShow(true);
                                  }
                                }
                              }}
                              className="me-3 pointer"
                            >
                              <img src="img/mgs_icon.svg" alt="mgs icon" />
                            </span>
                          )}
                          {ele?.website &&
                            <span className="me-3 pointer">
                              <a href={ele?.website} target="_blank">
                                <img src="img/website_icon.svg" alt="website_icon" />
                              </a>
                            </span>}
                        </div>
                      </td>
                    </tr>
                  );
                })

              ) :
                (
                  data?.data.map((ele, index) => {
                    return (
                      <tr key={index} className="mt-0">

                        <td className="mobile100 position-relative">
                          {/* <div className="mobileH">Business Name</div> */}
                          <div className="d-flex">
                            <span className="bluetick d-none d-md-block">{ele?.status === "verified" && <img src="img/checkBlue.svg" alt="checkBlue" className="me-1" />}</span>
                            <div className="mobile_boltxt"> {ele?.businessname}  <span className="bluetick d-md-none d-inline">{ele?.status === "verified" && <img src="img/checkBlue.svg" alt="checkBlue" className="ms-1" />}</span></div>

                          </div>
                          {isFavPanel && !groupId && <div className="mobile__checkbox">
                            <input type="checkbox"
                              id={`checkboxWeb-${ele.id}`}
                              value={ele.id}
                              checked={ele.selectAllUsers || false}
                              onChange={(e) => onCheckboxChange(e, ele.id)}
                            />
                          </div>}
                        </td>
                        <td>
                          <div className="mobileH">Contact Name</div>
                          {ele?.primaryprincipalname}
                        </td>
                        <td>
                          <div className="mobileH">City</div>
                          {ele?.city} {ele?.state} {ele?.zip}
                        </td>
                        {/* <td>
                          <div className="mobileH">State</div>
                          {ele?.state}
                        </td>
                        <td className="text-nowrap">
                          <div className="mobileH">Zip</div>
                          {ele?.zip}
                        </td> */}
                        <td>
                          <div className="mobileH">Lic. Type</div>
                          {ele?.contractorlicensetypecodedesc}
                        </td>
                        {/* <td>
                      <div className="mobileH">SP Code</div>
                      {ele?.specialtycode1}
                    </td> */}
                        <td>
                          <div className="mobileH">SP Code Desc</div>
                          {ele?.specialtycode1desc}
                        </td>
                        {/* <td>
                      <div className="mobileH">Subscriber</div>Y
                    </td> */}
                        <td className="text-nowrap">
                          <div className="mobileH">Exp. DT.</div>
                          {formatDate(ele?.licenseexpirationdate)}
                        </td>
                        {/* <td>
                      <div className="mobileH">View Details</div>
                      <span
                        onClick={() => {
                          setUserId(ele?.id);
                          setIsOpen(true);
                        }}
                        className="me-2 pointer"
                      >
                        <img src="img/tbl_eye.svg" alt="eye icon" title="View Details	" />
                      </span>
                     
                    </td> */}
                        <td>
                          <div className="mobileH">Action</div>
                          <div className="d-flex align-items-center">
                            <span
                              onClick={() => {
                                setIsUserVerified(ele.status === "verified" ? true : false)
                                setUserId(ele?.id);
                                setIsOpen(true);
                              }}
                              className="me-3 pointer"
                            >
                              <img src="img/tbl_eye.svg" alt="eye icon" title="View Details	" />
                            </span>

                            {
                              ele.favorites == 1 && !groupId ? (
                                <img
                                  src="img/favorites.svg"
                                  alt="favorites"
                                  className="pointer favorite-icon me-3"
                                  title="Remove from Favorites"
                                  onClick={() => {
                                    console.log("trigegegr")
                                    if (favIds.length > 0) {
                                      alert("info", "Remove all checked licenses to access this feature")
                                    } else {
                                      if (localStorage.getItem("user_token")) {
                                        toggleFavorite(ele.id);
                                        onClickFavourite(ele.id);
                                      } else {
                                        setShow(true);
                                        setTypeOfModal("login");
                                      }

                                    }
                                  }}
                                />
                              ) : (
                                !groupId && (
                                  <img
                                    src="img/favorite_nill.svg"
                                    alt="favorites"
                                    className="pointer favorite-icon me-3"
                                    title="Add to Favorites"
                                    onClick={() => {
                                      if (localStorage.getItem("user_token")) {
                                        toggleFavorite(ele.id);
                                        onClickFavourite(ele.id);
                                      } else {
                                        setShow(true);
                                        setTypeOfModal("login");
                                      }
                                    }}
                                  />
                                )
                              )
                            }





                            {ele.message ? (
                              <span
                                onClick={() => alert("info", "Already sent SMS")}
                                className="me-3 pointer"
                              >
                                {" "}
                                <img src="img/chatdisable.svg" alt="mgs icon" />
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  if (deviceType === "Desktop") {
                                    alert("info", "This feature only works in mobile device");
                                  } else {
                                    if (!localStorage.getItem("user_token")) {
                                      setTypeOfModal("login")
                                      setUserDetails("Please register with your business phone number, email, and mobile number to access SMS feature.")
                                      // setUserDetails(ele);
                                      setShow(true);
                                    } else {
                                      setTypeOfModal("info");

                                      setUserDetails(ele);
                                      setShow(true);
                                    }
                                    // if (!sharedData.postId && !localStorage.getItem("user_token")) {
                                    // } else if (sharedData.postId) {
                                    //   setUserDetails(ele);
                                    //   setShow(true);
                                    // } else {
                                    //   alert("info", "Please select job type");
                                    // }
                                  }
                                }}
                                className="me-3 pointer"
                              >
                                <img src="img/mgs_icon.svg" alt="mgs icon" />
                              </span>
                            )}
                            {ele?.website &&
                              <span className="me-3 pointer">
                                <a href={formatUrl(ele?.website)} target="_blank">
                                  <img src="img/website_icon.svg" alt="website_icon" />
                                </a>
                              </span>}
                          </div>
                        </td>
                        {isFavPanel && !groupId && <td className="no-need-mobile">


                          <input
                            className="inputFill"
                            type="checkbox"
                            id={`checkboxWeb-${ele.id}`}
                            value={ele.id}
                            checked={ele.selectAllUsers || false}
                            onChange={(e) => onCheckboxChange(e, ele.id)}
                          />
                        </td>
                        }

                      </tr>
                    );
                  })
                ))
            }
            <Loader type={"tableLines"} />
          </tbody>
        </table>

        {!isLoading && (
          <>
            {(groupData?.data?.length === 0 || data?.data?.length === 0) && (
              <p className="mt-4" style={{ textAlign: "center" }}>
                No data found
              </p>
            )}
          </>
        )}

      </div>
      <div className="d-flex align-items-center justify-content-between mt-4">
        <div className="page d-xl-flex align-items-center text-nowrap d-none">
          {(groupData?.totalRecords > 0 || data?.totalRecords > 0) && (
            <span>
              Showing {(groupData?.totalRecords || data?.totalRecords)}
              {(groupData?.totalRecords === 1 || data?.totalRecords === 1) ? ' Entry' : ' Entries'}
            </span>
          )}
        </div>

        {!isLoading && (
          <>
            {((groupData && groupData?.data?.length > 0) || (data && data?.data?.length > 0)) && (
              <div className="paggtbl ms-auto">
                <MyPagination
                  key={currentPage}
                  onChange={(e) => setCurrentPage(e)}
                  current={currentPage}
                  total={groupData ? groupData?.totalRecords : data?.totalRecords}
                  limit={40}
                />
              </div>
            )}
          </>
        )}
      </div>
      {/* <div className="table_footer-submit mt-4">
        <div className="d-flex">
          <button className="btn_cus btnMinWd me-4 py-12p">Save</button>
          <button className="btn_cus btnMinWd  py-12p br_white_btn">Cancel</button>
        </div>
      </div> */}
      <CustomDrawer
        type={!isUserVerified ? "" : "up"}
        open={isOpen}
        onClose={onClose}
        body={isUserVerified ? <UserProfile isUserVerified={isUserVerified} verifiedUserId={userId} is0pen={isOpen} onClose={onClose} /> : <ContractorProfile is0pen={isOpen} userId={userId} isUserVerified={isUserVerified} onClose={onClose} />}
      />

      <CustomModal
        isBulkSMS={isChecked && !groupId ? true : false}
        setShow={setShow}
        deviceType={deviceType}
        modalBodyText={userDetails || ""}
        addGroup={typeOfModal === 'createGroup' ? createGroup : ''}
        // handleChange={onCickSendSMS}
        show={show}
        type={typeOfModal ? typeOfModal : "info"}
        handleClose={() => {
          setShow(false);
          setUserDetails("");
        }}
        onHide={() => {
          setUserDetails("");
          setShow(false);
        }}
      />
    </>
  );
};

export default DashboardList;

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/SharedData";
import Axios from "../../axios/axiosInstance";
import { logout } from "../../utils/logout";
import alert from "../../utils/alert";
import Dropdown from "react-bootstrap/Dropdown";
import { CONTRACTOR_TYPE } from "../../config/contractorType";
import JobTypeFilter from "../common/JobTypeFilter";
import { resetLocalStorageItems } from "../../utils/resetLocalStorageItems";
import { UserProfile } from "../../pages/profile/UserProfile";
import { CustomDrawer } from "../common/CustomDrawer";
import getLicenseList from "../../api/getLicenseList";
import useLoader from "../loaders/useLoader";
import NotificationsList from "../../pages/notifications/NotificationsList";
import { classNames } from "primereact/utils";
import { type } from "@testing-library/user-event/dist/type";

const Header = () => {
  let debounceTimer;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const notify = queryParams.get("notify")
  const qredirect = queryParams.get("qredirect")


  const imgstore = localStorage.getItem("p-img") || null  //handling profile image if changes on edit
  const { showLoader, hideLoader, Loader, isLoading } = useLoader();
  const { setIsClaimBtnActive, setSubscriptionStatus, sharedData, setSharedData, setContractorDetails, contractorDetails, setRefreshPage, refreshPage, isWebsiteRedirected, setIsWebsiteRedirected } = useAppContext();

  const [typeOfdrawer, setTypeOfDrawer] = useState("")

  //it works in mobile device for hide/view filters
  const [toggleFilters, setToggleFilters] = useState(false);
  const [userProfileList, setUserProfileList] = useState([])   //account created by same user
  const [isOpen, setIsOpen] = useState(false);
  const [showNotificationBadge, setShowNotificationBadge] = useState(false)

  const onClose = () => {
    setIsOpen(false);
    setIsWebsiteRedirected(false)
    if (notify || qredirect) {
      navigate(location.pathname)
    }
  };


  useEffect(() => {
    if (qredirect && localStorage.getItem("user_token")) {
      setTypeOfDrawer("profile")
      setIsOpen(true)
    } else if (notify) {
      setTypeOfDrawer("notifications")
      setIsOpen(true)
    } else if (isWebsiteRedirected) {
      setTypeOfDrawer("profile")
      setIsOpen(true)
    } else {
      onClose()
    }

  }, [location])


  useEffect(() => {
    localStorage.getItem("user_token") && getUserProfile()
    setToggleFilters(localStorage.getItem("type") || false)
    setSharedData((prevData) => ({ ...prevData, type: localStorage.getItem("type") || "", search: localStorage.getItem("search") || "", jobFilter: localStorage.getItem("jobFilter") || "", postId: localStorage.getItem("postId"), city: localStorage.getItem("city") || "", zip: localStorage.getItem("zip") || "" }));
  }, [])

  // Handler function for input change
  const handleInputChange = (e) => {
    const { value } = e.target;

    // Clear the previous debounce timer
    clearTimeout(debounceTimer);

    // Set a new debounce timer
    setSharedData((prevData) => ({ ...prevData, search: value }));
    localStorage.setItem("search", value)
    debounceTimer = setTimeout(() => { }, 300); // Adjust debounce delay as needed 
  };

  const handleSelectCType = (e) => {
    setSharedData((prevData) => ({ ...prevData, type: e, jobFilter: "", postId: "" }));
    localStorage.setItem("type", e)
    resetLocalStorageItems("jobFilter", "postId")
    setContractorDetails("");
  };

  useEffect(() => {
    localStorage.getItem("user_token") && getUserProfile()
  }, [refreshPage])


  const getUserProfile = async () => {
    showLoader()
    await Axios.get(
      `me/license`
    )
      .then((res) => {
        setShowNotificationBadge(res.data.read === "no" ? false : true)
        setUserProfileList(res.data.data)
        setSubscriptionStatus(res.data.data[0].subscription_type)
        if (res?.data?.data[0]?.total > 0) {
          setIsClaimBtnActive(true)
        } else {
          setIsClaimBtnActive(false)
        }
      })
      .catch((err) => {
        err.response && err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        setRefreshPage(false)
        hideLoader();
      });
  };


  return (
    <>
      <Loader />

      <div className={`header ${(!localStorage.getItem("user_token") || userProfileList[0]?.subscription_type === "free") && "notification__remove--head"}`}>
        {/* {localStorage.getItem("sub_type") == "free" && */}
        {(!localStorage.getItem("user_token") || userProfileList[0]?.subscription_type === "free") &&
          <div onClick={() => {
            if (localStorage.getItem("user_token")) {
              navigate("/choose-plan", { state: { isUpgradePlan: false } })
            }
          }
          }
            className="notification__top mb-3 "><div className="d-flex">
              <img src="img/notification__top.svg" alt="icon" className="me-2" />
              <span className="d-block"> You are not subscribed. Become a Platinum member.</span>
            </div></div>
        }
        <div className="d-xl-flex align-items-center">
          <div className="logo_mobile">
            <img src="img/inside_logo.svg" alt="logo" />
          </div>
          <div className="d-flex align-items-center msch">
            {(location.pathname === "/" || location.pathname === "/map") && (
              <div className="tabMap d-flex me-xxl-4 me-3">
                <div className="tabMap-inG">
                  <input
                    onClick={() => {
                      navigate("/");
                    }}
                    checked={!location.pathname.includes("map")}
                    type="radio"
                    id="documentV"
                    className="d-none"
                    name="maptab"
                  />
                  <label htmlFor="documentV">
                    <img src="img/tabDC.svg" alt="tabDC" />
                  </label>
                </div>
                {/* <div className="tabMap-inG">
                  <input
                    onClick={() => {
                      navigate("/map");
                    }}
                    type="radio"
                    id="mapV"
                    className="d-none"
                    name="maptab"
                    checked={location.pathname.includes("map")}
                  />
                  <label htmlFor="mapV">
                    <img src="img/tabMap.svg" alt="tabMap" />
                  </label>
                </div> */}
              </div>
            )}
            {((location.pathname === "/" || location.pathname === "/map") || location.pathname === "/post" || location.pathname === "/favourite") && (
              <>
                <div className="search me-xxl-4 me-xl-3">
                  <input
                    onChange={handleInputChange}
                    type="search"
                    value={sharedData.search}
                    className="form-control search_icon"
                    placeholder="Search"
                  />
                </div>
                {(location.pathname === "/" || location.pathname === "/map") &&
                  <div className="filterHBox ms-auto">
                    {toggleFilters || contractorDetails ? (
                      <img
                        onClick={() => {
                          setToggleFilters(!toggleFilters)
                          localStorage.setItem("toggleFilters", !toggleFilters)
                        }}
                        src="img/filterMenu-header.svg"
                        alt=" filterMenu"
                        className="ms-xl-3 ms-2"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          setToggleFilters(!toggleFilters)
                          localStorage.setItem("toggleFilters", !toggleFilters)
                        }}
                        src="img/filterMenu-header_unactive.svg"
                        alt=" filterMenu"
                        className="ms-xl-3 ms-2"
                      />
                    )}
                  </div>}
              </>
            )}
          </div>
          <div className="d-flex w-100">
            {(location.pathname === "/" || location.pathname === "/map") && (
              <div
                className={`filter_mobile d-flex align-items-center ${toggleFilters ? null : "filterToggle"
                  }`}
              >
                <div className="filter-dropdown">
                  <Dropdown
                    onSelect={handleSelectCType}
                    className="dropdownheader  me-sm-3 me-2 max200 "
                  >
                    <Dropdown.Toggle style={{ color: "black" }} variant="secondary" className="mt-0">
                      {sharedData.type || "Contractor Type"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <React.Fragment key="All">
                        <Dropdown.Item eventKey="All">All</Dropdown.Item>
                        <Dropdown.Divider />
                      </React.Fragment>
                      {CONTRACTOR_TYPE.map((ele) => {
                        return (
                          <React.Fragment key={ele}>
                            <Dropdown.Item eventKey={ele}>{ele}</Dropdown.Item>
                            <Dropdown.Divider />
                          </React.Fragment>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <input
                  onChange={(e) => {
                    // setZipcode(e.target.value);
                    setSharedData((prevData) => ({
                      ...prevData,
                      zip: e.target.value,
                      jobFilter: "",
                      postId: "",
                    }));
                    localStorage.setItem("zip", e.target.value)
                    resetLocalStorageItems("jobFilter", "postId")
                    setContractorDetails("");
                  }}
                  type="search"
                  className="form-control max200 min-wd120 me-sm-3 me-2"
                  placeholder="Zip Code"
                  value={sharedData.zip}
                />
                <input
                  onChange={(e) => {
                    setSharedData((prevData) => ({ ...prevData, city: e.target.value }));
                    localStorage.setItem("city", e.target.value)
                  }}
                  type="search"
                  className="form-control max200 min-wd120 me-sm-3 me-2"
                  placeholder="City"
                  value={sharedData.city || ""}
                />

                <div className="filter-dropdown filterHiring d-xl-none">
                  <JobTypeFilter />
                </div>
                <div
                  onClick={() => {
                    setContractorDetails("");
                    resetLocalStorageItems("search", "city", "zip", "postId", "jobFilter", "type")
                    setSharedData((prevData) => ({
                      ...prevData,
                      search: "",
                      type: "",
                      city: "",
                      zip: "",
                      jobFilter: "",
                      postId: "",
                    }));
                  }}
                  className="clearFilter "
                >
                  Clear All
                </div>
              </div>
            )}

          </div>

          <div className="end_col ms-auto d-flex align-items-center">
            {localStorage.getItem("user_token") &&
              <div onClick={() => {
                setTypeOfDrawer("notifications")
                setIsOpen(true)
              }
              } className="me-3 pointer position-relative" >

                <img src="img/bell.svg" alt="bell" />
                {showNotificationBadge &&
                  <span className="noti__dot"></span>}
              </div>}




            {/* profile image  */}
            {localStorage.getItem("user_token") ?
              <Dropdown className="logout-dropdown  d-flex">
                <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0 p-0">
                  <span className="pointer poistion-relative">
                    <img
                      className="logout"
                      src={
                        imgstore && imgstore != "null" ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + localStorage.getItem("p-img") : "img/logout.png"
                      }
                      alt="profile icon"
                    />

                    {localStorage.getItem("user_token") &&
                      <div>
                        {(userProfileList[0]?.subscription_name?.toLowerCase()?.includes("platinum") || userProfileList[0]?.subscription_name?.toLowerCase()?.includes("diamond")) && (
                          <div className="profile__on__icon">
                            {userProfileList[0]?.subscription_name?.toLowerCase().includes("platinum") ? (
                              <>
                                <img src="img/platinum__icon.svg" alt="Platinum" className="me-0" />

                              </>
                            ) : (
                              <>
                                <img src="img/diamond__icon.svg" alt="Diamond" className="me-0" />

                              </>
                            )}
                          </div>
                        )

                        }
                      </div>
                    }
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    onClick={() => {
                      navigate("/choose-plan")
                    }}
                    as="button"
                  >
                    <img src="img/bell.svg" alt="login" />
                    <span className="ps-2">Subscribe</span>{" "}
                  </Dropdown.Item> */}
                  {userProfileList?.map((ele, index) => {
                    return <Dropdown.Item
                      key={index}
                      onClick={() => {
                        if (ele.license_id != localStorage.getItem("l_id")) {
                          setContractorDetails("")
                          localStorage.removeItem("type")
                          localStorage.removeItem("jobFilter")
                          localStorage.removeItem("postId")
                          setSharedData((prevData) => ({ ...prevData, jobFilter: "", type: "" }));
                          setRefreshPage(!refreshPage)
                          navigate("/")
                          alert("success", "Profile switched successfully")
                        }
                        localStorage.setItem("l_id", ele.license_id)
                        localStorage.setItem("l_name", ele.contractorlicensetypecodedesc)
                        localStorage.setItem("zip_code", ele.zip);
                        getUserProfile()
                      }}
                      as="button"
                    >
                      <img src="img/user.png" alt="login" />
                      <span className="ps-2">{ele?.contractorlicensetypecode}-{ele?.contractorlicensenumber
                      }</span>
                      <span>{ele.license_id == localStorage.getItem("l_id") && <img className="ps-2" src="img/tik.svg" />}</span>
                    </Dropdown.Item>
                  })}

                  <Dropdown.Item
                    onClick={() => {
                      if (userProfileList[0]?.subscription_name?.toLowerCase()?.includes("platinum") || userProfileList[0]?.subscription_name?.toLowerCase()?.includes("diamond")) {
                        navigate("/plans")
                      } else {
                        navigate("/choose-plan", { state: { isUpgradePlan: false } })
                      }
                    }}
                    as="button"
                  >
                    <img src="img/user.png" alt="login" />
                    <span className="ps-2">Plan & Payments</span>{" "}
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setTypeOfDrawer("profile")
                      setIsOpen(true)
                    }}
                    as="button"
                  >
                    <img src="img/user.png" alt="login" />
                    <span className="ps-2">My Profile</span>{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      Axios.get(`logout`)
                        .then((res) => {
                          if (res) {
                            alert("success", "Logged out successfully");
                            resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId", "l_id", "b_name", "zip_code", "user_token", "user_id", "c_id", "FCM_TOKEN", "p-img")
                            localStorage.removeItem("user_token");
                            localStorage.removeItem("user_id");
                            setContractorDetails("")
                            navigate("/login")
                            // logout();
                          }
                        })
                        .catch((err) => {
                          err.response && console.log("err", err);
                        });
                    }}
                    as="button"
                  >
                    <img src="img/Logout.svg" alt="logout" />
                    <span className="ps-2">Logout</span>{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> :
              <div onClick={() => navigate("/login")} className="loginBnt d-flex align-items-center pointer">
                {/* <img src="img/logginwhite.svg" alt="login" /> */}
                <span className="DMSans_bold">Login</span>
              </div>}
          </div>
        </div>
      </div>
      <CustomDrawer type={typeOfdrawer !== "profile" ? "" : "up"} open={isOpen} onClose={onClose} body={typeOfdrawer === "profile" ? <UserProfile onClose={onClose} is0pen={isOpen} /> : <NotificationsList key={typeOfdrawer} onClose={onClose} is0pen={isOpen} />} />
    </>
  );
};

export default Header;

import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/SharedData";
import CustomModal from "../common/CustomModal";
import { resetLocalStorageItems } from "../../utils/resetLocalStorageItems";

const Sidebar = () => {
  const { setSharedData, setContractorDetails, setRefreshPage } = useAppContext();
  const [show, setShow] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const resetFilter = () => {
    setContractorDetails("");
    setSharedData((prevData) => ({
      ...prevData,
      search: "",
      type: "",
      city: "",
      zip: "",
      jobFilter: "",
      postId: "",
    }));
    resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId")
  };

  return (
    <>
      <div className="sidebar">
        <h1 className="text-center logo_dash mobile_12none">
          <img src="img/logo.svg" alt="logo" />
        </h1>
        <ul className={`sidebar_grp ps-0 ${localStorage.getItem("user_token") ? "" : "loginside"}`}>
          <li onClick={() => resetFilter()}>
            <NavLink
              to={"/"}
              className={
                location.pathname.includes("/map")
                  ? "active d-flex align-items-center"
                  : "d-flex align-items-center"
              }
            >
              <span>
                <img src="img/dashboard.svg" alt="dashboard" />
              </span>
              <span className="ms-2 d-xl-block d-none">Dashboard</span>
              <span className="ms-2 d-xl-none d-block">Home</span>
            </NavLink>
          </li>
          <li onClick={() => resetFilter()}>
            <NavLink to={"/post"} className="d-flex align-items-center">
              <span>
                <img src="img/post.svg" alt="Post" />
              </span>
              <span className="ms-2">Post</span>
            </NavLink>
          </li>
          {/* <li onClick={() => resetFilter()}>
            <NavLink to={"/chat"} className="d-flex align-items-center">
              <span>
                <img src="img/chat.svg" alt="Chat" />
              </span>
              <span className="ms-2">Chat</span>
            </NavLink>
          </li>
          <li onClick={() => resetFilter()} className="mobile_12none">
            <NavLink to={"menu"} className="d-flex align-items-center">
              <span>
                <img src="img/menu.svg" alt="Menu" />
              </span>
              <span className="ms-2">Menu</span>
            </NavLink>
          </li> */}

          {localStorage.getItem("user_token") &&
            <li
              onClick={() => {
                if (localStorage.getItem("user_token")) {
                  resetFilter();
                  navigate("/favourite");
                } else {
                  setShow(true);
                  setTypeOfModal("login");
                  return;
                }
              }}
            >
              {localStorage.getItem("user_token") ? (
                <NavLink onClick={() => setRefreshPage(true)} to={"favourite"} className="d-flex align-items-center">
                  <span>
                    <img src="img/favorite.svg" alt="Favorite" />
                  </span>
                  <span className="ms-2">Favorite</span>
                </NavLink>
              ) : (
                <Link to={""} className="d-flex align-items-center">
                  <span>
                    <img src="img/favorite.svg" alt="Favorite" />
                  </span>
                  <span className="ms-2">Favorite</span>
                </Link>
              )}
            </li>}

            {localStorage.getItem("user_token") &&
          <li onClick={() => resetFilter()}>
            <NavLink to={"/new-permits"} className="d-flex align-items-center">
              <span>
                <img src="img/new-permits.svg" alt="new permits" />
              </span>
              <span className="ms-2">New Permits</span>
            </NavLink>
          </li>}
        </ul>
        {/* {localStorage.getItem("user_token") && (
          <button
            onClick={() => {
              Axios.get(`logout`)
                .then((res) => {
                  if (res) {
                    alert("success", "Logged out successfully");
                    resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId")
                    logout();
                  }
                })
                .catch((err) => {
                  err.response && console.log("err", err);
                });
            }}
            className="logout_btn mobile_12none"
          >
            <span>
              <img src="img/logout.svg" alt="logout" className="me-2" />
            </span>
            Logout
          </button>

        )} */}
      </div>
      <CustomModal
        type={typeOfModal}
        show={show}
        onHide={() => {
          setShow(false);
        }}
        handleClose={() => {
          setShow(false);
        }}
      />
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Axios from '../../axios/axiosInstance';
import useDivLoader from '../../components/loaders/useDivLoader';
import MyPagination from '../../components/Pagination/MyPagination';

const NewPermits = () => {

    const { showLoader, hideLoader, Loader, isLoading } = useDivLoader();
    const [notificationsList, setNotificationList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const navigate = useNavigate();


    useEffect(() => {
        getNotificationList();
    }, [currentPage]); // Dependency array ensures useEffect is triggered when `is0pen` changes

    const getNotificationList = async () => {
        showLoader()
        await Axios.get(`permits/user-issued?page=${currentPage}&limit=40`)
            .then((res) => {
                setNotificationList(res.data);
            })
            .catch((err) => {
                console.error("Failed to fetch notifications:", err);
                err.response && alert("error", err.response.data.error);
            }).finally(() => {
                hideLoader()
            })
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Extracting date in MM/DD/YYYY format
        const formattedDate = date.toLocaleDateString("en-US", {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        // Extracting time in 12-hour format with AM/PM
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${formattedDate} - ${formattedTime}`;
    };

    const extractNameAndAddress = (contractor) => {
        // Split the string by newline characters and filter out empty elements
        const lines = contractor.split('\n').filter(line => line.trim() !== '');

        // Extract name (second element) and address (from third element onwards)
        const name = lines[1];  // Name is typically on the second line
        const address = lines.slice(2).join(' ');  // Address starts from the third line

        return {
            name,
            address
        };
    };






    return (
        <>
            <h2 className="title">New Permits</h2>
            <div className="page d-xl-none mt-3">
                <span>
                    {notificationsList?.totalRecords > 0 && (
                        <span>
                            Showing {(notificationsList?.totalRecords)}
                            {(notificationsList?.totalRecords === 1) ? ' Entry' : ' Entries'}
                        </span>
                    )}
                </span>
            </div>
            <div className='tbwrp  mt-3 '>
                <table className="ldr" class="minW1470">
                    <thead>
                        <tr>
                            {/* <th>Expiration Date / Time </th> */}
                            <th>Permit Number</th>
                            <th width="10%">Permit Type</th>
                            <th width="7%">Status</th>
                            <th>Description</th>
                            <th>Construction Address</th>
                            <th>Permit Applicant</th>
                            <th class="action">Action</th>
                        </tr>
                    </thead>
                    <tbody className="ldr">
                        {notificationsList.data?.map((ele) => {
                            return <tr>
                                {/* <td>
                                    <div className="mobileH">issued Date / Time</div>
                                    {formatDate(ele.expiration)}
                                </td> */}
                                <td className="mobile100 position-relative">
                                    <div className="mobileH">Permit Number</div>
                                    <div className="mobile_boltxt"> {ele?.permitnumber}</div>

                                </td>
                                <td>
                                    <div className="mobileH">Permit Type</div>
                                    {ele?.permittype}
                                </td>
                                <td>
                                    <div className="mobileH">Status</div>
                                    {ele?.status}
                                </td>
                                <td>
                                    <div className="mobileH">Descripion</div>
                                    <div className="eclip1">{ele?.description}</div>
                                </td>
                                <td>
                                    <div className="mobileH">Construction Address</div>
                                    {extractNameAndAddress(ele?.contractor)?.address || "N/A"}
                                </td>
                                <td>
                                    <div className="mobileH">Permit Applicant</div>
                                    {extractNameAndAddress(ele?.contractor)?.name || "N/A"}

                                </td>

                                <td>
                                    <div className="mobileH">Action</div>
                                    <span className="me-3 pointer">
                                        <img onClick={() => navigate(`/notification/newpermit/pId/${ele.id}`)} src="img/tbl_eye.svg" alt="eye icon" title="View Details" />
                                    </span>
                                </td>
                            </tr>
                        })}
                        <Loader type={"tableLines"} />
                    </tbody>
                </table>
            </div>

            {!isLoading && (
                <>
                    {(notificationsList?.data?.length === 0) && (
                        <p className="mt-4" style={{ textAlign: "center" }}>
                            No data found
                        </p>
                    )}
                </>
            )}

            <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="page d-xl-flex align-items-center text-nowrap d-none">
                    {notificationsList?.totalRecords > 0 && (
                        <span>
                            Showing {(notificationsList?.totalRecords)}
                            {(notificationsList?.totalRecords === 1) ? ' Entry' : ' Entries'}
                        </span>
                    )}
                </div>

                {!isLoading && (
                    <>
                        {(notificationsList && notificationsList?.data?.length > 0) && (
                            <div className="paggtbl ms-auto">
                                <MyPagination
                                    key={currentPage}
                                    onChange={(e) => setCurrentPage(e)}
                                    current={currentPage}
                                    total={notificationsList?.totalRecords}
                                    limit={40}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default NewPermits